/* This file contain HRIIS THEME v1.0 */

/*
====================================
   Theme Variables
====================================
*/
:root {
    --border-radius: 3px;
    /* --default: #ffffff;
    --white: #ffffff;
    --black: #292929;
    --dark: #292929;
    --dark-hover: #1a1a1a;
    --light: #F2F4F6; */
    /* --light-hover: #F2F4F6; */
    /* --light-grey: #eeeeee;

    --primary: #6b48ff;
    --primary-hover: #552fe4;
    --info: #31C6E8;
    --info-hover: #1bafd1;
    --success: #1EE3CF;
    --success-hover: #16c8b6;
    --warning: #EEE722;
    --warning-hover: #c9c313;
    --danger: #FF4C78;
    --danger-hover: #e73863;
    --blue: #31C6E8;
    --iceblue: #1EE3CF;


    --border: #ccc;
    --radius: 0.0625rem;
    --radius-circled: 2rem; */

    /* Text Colors */
    /* --body: --black;
    --variant1: #313131;
    --variant2: #6C757D;
    --variant3: #9F9F9F;
    --variant4: #C6C6C6;
    --variant5: #e7e7e7; */
}

/*
====================================
   Theme Fonts
====================================
*/
@font-face {
    font-family: 'Daniel';
    src: url('/public/fonts/daniel.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sansation';
    src: url('/public/fonts/Sansation_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url('/public/fonts/Dosis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*
====================================
    Theme Default Styles
====================================
*/
html {
    scroll-behavior: smooth;
}


body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Dosis' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.toast-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f5f5f5;
    /* light gray background */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 300px;
    z-index: 1000;
}

.toast-content {
    padding: 20px;
    text-align: center;
    color: #333;
    /* dark gray text */
}

.toast-content h4 {
    font-weight: bold;
    margin-bottom: 10px;
}

.toast-content button {
    background-color: var(--primary);
    /* blue background */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.toast-content button:hover {
    background-color: var(--primary-hover);
}

/* add media queries to adjust styles on mobile/tablet */
@media (max-width: 768px) {
    .toast-notification {
        width: 100%;
        bottom: 0;
        right: 0;
        border-radius: 0;
    }
}

@media (max-width: 480px) {
    .toast-notification {
        width: 100%;
        bottom: 0;
        right: 0;
        border-radius: 0;
    }
}

/*
====================================
    Bouncing Loader animation
====================================
*/
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10%;
}

/* Loader circles */
.loader>span {
    background: var(--primary);
    border-radius: 50%;
    margin: 5rem 0.5rem;
    animation: bouncingLoader 0.6s infinite alternate;
}

.loader>span:nth-child(2) {
    animation-delay: 0.2s;
}

.loader>span:nth-child(3) {
    animation-delay: 0.4s;
}

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
    from {
        width: 0.5rem;
        height: 0.5rem;
        opacity: 1;
        transform: translate3d(0);
    }

    to {
        width: 2rem;
        height: 2rem;
        opacity: 0.1;
        transform: translate3d(0, -2rem, 0);
    }
}

.rdw-editor-toolbar.rich-text__toolbar {
    display: none;
}

.rdw-editor-wrapper:focus-within .rich-text__toolbar {
    display: flex;
}

/* mdx-editor overrides */
.mdxeditor {
    border: 1px solid #e2e2e2;
    min-height: 250px;
    height: auto;
}

.custommdxeditor {
    border: 1px solid #e2e2e2;
    min-height: 250px;
    height: auto;
    font-size: 0.8rem;
}

.custommdxeditor ul,
.custommdxeditor ol,
.mdxeditor ul,
.mdxeditor ol {
    padding-left: 1.5rem;
}

._popupContainer_yms4a_1220 {
    z-index: 1901 !important;
}

.modal .content ._linkDialogAnchor_yms4a_586 {
    z-index: 1900 !important;
    opacity: 0.8;
}

/*
====================================
SemanticUI Overides Variables
====================================
*/
.ui.search .prompt {
    border-radius: 0;
    width: 500px;
}

.ui.images .image,
.ui.images>img,
.ui.images>svg {
    margin: 0 !important;
}

/* Cards */
.card.event-type-active,
.card.event-type-active:hover {
    background-color: var(--primary);
}

.card.registration-account-type {
    border: 3px solid var(--light);
}

.card.registration-account-type:hover {
    border: 3px solid var(--primary);
}

.ui.segment {
    border-radius: 0 !important;
}

input,
select,
textarea,
.dropdown {
    border-radius: var(--border-radius) !important;
}

.ui.selection.dropdown,
.ui.search.selection.dropdown {
    border-radius: var(--border-radius) !important;
}

button:not(.circular) {
    border-radius: var(--border-radius) !important;
}

.modal {
    border-radius: var(--border-radius) !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: var(--primary) !important;
}

a {
    color: var(--primary) !important;
    text-decoration: none;
}

.header {
    font-family: 'Dosis' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.primary-super-button {
    color: var(--primary) !important;
    box-shadow: inset 0 0 0 1px var(--primary) !important;
}

.password-input-wrapper {
    position: relative;
}

.password-icon {
    position: absolute;
    top: 38%;
    right: 10px;
    transform: translateY(-38%);
    cursor: pointer;
    opacity: 0.5 !important;
    z-index: 10;
    /* Ensure the icon is on top of the input field */
}

/*
====================================
    Error Page Styles
====================================
*/
.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    height: 100vh;
    width: 100%;
    text-align: center;
    color: var(--light);
}

.error-page code {
    font-size: 10rem;
    text-align: center;
}

.error-page p {
    margin: 0;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
}

.error-page a {
    margin: 0;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
}

div.center-element {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
}

/*
====================================
    Custom Styles
====================================
*/
div.center-segment {
    height: auto;
}

.dashboard-h3 {
    line-height: 1.5rem !important;
}

/*
====================================
    Styles for Responsive Design
====================================
*/
@media (min-width: 320px) {
    table {
        display: block;
    }
}

@media (min-width: 768px) {
    table {
        display: table;
    }
}

/*
====================================
    Invoices sidebar
====================================
*/