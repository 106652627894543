/* This file contain all supported theming (light, dark) */

/* LIGHT */
.light {
    background: #f2f4f63b !important;
    scrollbar-color: var(--variant3) var(--light-grey);
}

/* .light #root {
    background: var(--light);
} */

.light .flex-table {
    border: 1px solid #ebebeb;
}

.light .flex-table > .flex-row:first-child {
    background: var(--light) !important;
    border-bottom: 1px solid #ebebeb;
}

.light .h3-dashboard {
    color: var(--dark) !important;
}

.light .bigMenu .top.menu {
    background: var(--white) !important;
}

.light .hexagon-title {
    color: var(--dark);
}

.light .hexagon-icon {
    color: var(--light);
}

.dark .modal-cancel {
    background: var(--variant5);
}

/* DARK */
.dark {
    background: #212121 !important;
    color: var(--light) !important;
    scrollbar-color: var(--dark-hover) var(--dark) !important;
}

/* .dark #root {
    background: #212121;
} */

.dark .ui.loading.segment::before {
    background: #232323;
}

/* .dark .fixed-sidebar > .sidebar{
    background: var(--dark-hover) !important;
    color: var(--light) !important;
} */

.dark .center-element > h1 {
    color: var(--light);
}

.dark .h3-dashboard {
    color: var(--light) !important;
}

.dark .bigMenu .top.menu {
    background: #131313 !important;
}

.dark .smallMenu .top.menu {
    background: #131313 !important;
}

.dark .hexagon-title {
    color: var(--light);
}

.dark .hexagon-icon {
    color: var(--dark);
}

.dark .bigMenu .right.menu .item {
    color: var(--light) !important;
}

.dark .smallMenu .right.menu .item {
    color: var(--light) !important;
}

.dark .bigMenu .search-bar input {
    background: var(--dark) !important;
    color: var(--light) !important;
}

.dark .bigMenu .search-bar .icon {
    color: var(--light) !important;
}

.dark .breadcrumb {
    color: var(--light) !important;
    background: transparent !important;
}

.dark .breadcrumb .divider {
    color: var(--light) !important;
    background: transparent !important;
}

.dark .breadcrumb .icon.divider {
    color: var(--light);
}

/* .dark .wrapper > .sidebar {
    background: #131313;
    color: var(--light);
    border-right: 1px solid var(--dark-hover);
} */

/* .dark .sidebar-category {
    color: var(--light);
} */

/* .dark .sidebar-category .sub {
    color: var(--light) !important;
} */

/* .dark .sidebar-item {
    color: var(--light) !important;
    border-left: 4px solid var(--dark);
    border-bottom: 1px solid var(--dark);
}

.dark .sidebar-item .icon {
    color: var(--light) !important;
} */

/* .dark .siderbar-list > .button{
    color: var(--light) !important;
    background: "transparent" !important;
}

.dark .siderbar-list > .button:hover{
    color: var(--light) !important;
    background: #131313 !important;
}

.dark .siderbar-list > .button:focus{
    color: var(--light) !important;
    background: #131313 !important;
} */

/* BUTTONS */
.dark .modal-cancel {
    background: var(--dark);
    color: var(--light);
}

.dark .modal-cancel:hover {
    background: var(--dark-hover);
    color: var(--light);
}

.dark .basic .ui.button {
    color: var(--light) !important;
    /* background: var(--dark) !important; */
}

.dark .basic .ui.button:hover {
    color: var(--light) !important;
    background: var(--dark-hover) !important;
}

.dark .basic .buttons .button {
    color: var(--light) !important;
    background: var(--dark) !important;
}

.dark .ui.basic.active.button,
.dark .ui.basic.buttons .active.button {
    color: var(--light) !important;
    background: var(--dark-hover) !important;
}

.dark .basic .buttons .button:hover {
    color: var(--light) !important;
    background: var(--dark-hover) !important;
}

.dark .popup {
    color: var(--light);
    border-color: var(--dark-hover);
    background: #131313;
}

.dark .popup::before {
    background: #131313 !important;
}

.dark .compact .menu {
    background: 'transparent';
}

.dark .compact.menu .item {
    background: #131313 !important;
    color: var(--light);
}

.dark .filter-action {
    color: var(--light) !important;
}

.dark .tabular > a {
    background: var(--dark-hover) !important;
    color: var(--light) !important;
}

/* SIDEBAR - PUSHER */
.dark .vertical.overlay {
    background: #131313;
}

/* FLEXTABLE - ROW/ITEM */
.dark .flex-table {
    border: 1px solid var(--dark-hover);
}

.dark .flex-table > .flex-row:first-child {
    background: var(--dark-hover) !important;
    border-bottom: 2px solid var(--dark-hover);
}

.dark .flex-row {
    color: var(--light) !important;
    /* border-bottom: 1px solid var(--dark) !important;
    border-top: 1px solid var(--dark) !important; */
}

.dark .flex-table.hoverable > .flex-row:nth-child(1n + 2):hover {
    background: var(--dark-hover) !important;
}

.dark .flex-table.stripped > .flex-row:nth-child(2n + 1) {
    background: #252525 !important;
}

/* CARDS */
.dark .card {
    background: var(--dark-hover) !important;
    box-shadow: none !important;
    color: var(--light) !important;
}

/* FORM FIELDS */
.dark .ui.multiple.dropdown > .label {
    background: var(--dark-hover);
    color: var(--light) !important;
}

.dark .search input {
    background: var(--dark) !important;
    color: var(--light) !important;
}

.dark .results {
    background: var(--dark) !important;
    border-color: var(--dark-hover) !important;
}

.dark .results .result .content .title {
    color: var(--light) !important;
}

.dark .results .result:hover {
    background: var(--dark-hover) !important;
    color: var(--dark) !important;
}

.dark .ui.input > input {
    background: var(--dark) !important;
    color: var(--light) !important;
}

.dark .search .icon {
    color: var(--light) !important;
}

.dark .superfield .icon {
    color: var(--light) !important;
}

.dark .superfield > label {
    color: var(--light) !important;
}

.dark .superfield span {
    color: var(--light) !important;
}

.dark .field label {
    color: var(--light) !important;
}

.dark .field span {
    color: var(--light) !important;
}

.dark .checkbox label {
    color: var(--light) !important;
}

.dark .checkbox span {
    color: var(--light) !important;
}

.dark .checkbox input {
    background: var(--dark) !important;
}

.dark .superfield input {
    background: var(--dark) !important;
    color: var(--light) !important;
}

.dark textarea {
    background: var(--dark) !important;
    color: var(--light) !important;
}

.dark .dropdown .menu .item {
    background: var(--dark) !important;
    border-top: 1px solid #131313 !important;
    border: 1px solid #131313;
    color: var(--light) !important;
}

.dark .dropdown .menu .item:hover {
    background: var(--dark-hover) !important;
}

.dark .ui.selection.dropdown .menu {
    border-color: #131313;
}

.dark .ui.simple.visible.dropdown > .menu {
    border-color: #131313;
}

.dark .ui.selection.active.dropdown .menu {
    border-color: #131313;
}

.dark .ui.selection.active.dropdown {
    border-color: #131313;
}

.dark .ui.selection.dropdown {
    background: var(--dark);
    color: var(--light);
}

/* MODALS */
.dark .modal {
    background: #131313 !important;
}

.dark .modal .content {
    background: #131313 !important;
}

.dark .modal .header {
    color: var(--light) !important;
}

.dark .modal > div.header {
    background: #131313;
    border-bottom: 1px solid var(--dark);
    color: var(--light);
}

.dark .modal .content p {
    color: var(--light);
}

.dark .ui.basic.label {
    background: var(--dark-hover);
    border: 1px solid var(--dark);
    color: var(--light);
}

/* DROPDOWN MENU */
.dark .dropdown .menu {
    border-top: none;
    background: var(--dark);
}

.dark .dropdown .menu .item {
    border: none;
}

.dark .dropdown .menu .item .icon {
    color: var(--light);
}

.dark .dropdown .menu .item .text {
    color: var(--light);
}

.dark .header-block {
    background: var(--dark-hover);
    color: var(--light);
}

.dark .UserAvatar--inner {
    background: #131313 !important;
    color: var(--light);
}

.dark h3.header {
    color: var(--light);
}

.dark .segment {
    background: var(--dark-hover);
}

/* PAGINATION */
.dark .pagination > li > a,
.dark .pagination > li > span {
    background-color: var(--dark-hover);
    color: var(--light) !important;
}

.dark .pagination > li {
    border-color: var(--dark) !important;
}

.dark .prev,
.dark .next,
.dark .pagination-limits .button {
    color: var(--light) !important;
}

/* ICONS */
.dark .pencil {
    color: var(--light) !important;
}

.dark .filter {
    color: var(--light) !important;
}
